<template>
    <v-card tile elevation="0" min-height="180">
        <div v-if="terms?.content">
            <div name="terms" readonly v-html="terms?.content"></div>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
export default {
    props: {
        code: { type: String, default: "privacy" },
    },
    data: () => ({
        terms: null,
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            const { code } = this;
            const { terms } = await api.v1.terms.get({ code });
            this.terms = terms;
        },
    },
};
</script>

<style></style>
