var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "약관",
      "tabActive": "개인정보처리방침",
      "bg": "/images/test.jpg"
    }
  }), _c('page-section', [_c('terms-component', {
    attrs: {
      "code": "privacy"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }