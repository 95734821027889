<template>
    <client-page>
        <sub-visual sh="약관" tabActive="개인정보처리방침" bg="/images/test.jpg" />
        <page-section>
            <terms-component code="privacy" />
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        TermsComponent,
    },
    props: {
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
            });
        }
    },
};
</script>

<style lang="scss" scoped>
</style>